<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_ui") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ node[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn icon color="green" x-small @click="openEditModal(node)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <AdminModalForm
      ref="AdminModalEditForm"
      rows="20"
      :fieldsEdit="FIELDS_EDIT"
      @onSave="onEdit"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";

import { mapState, mapActions } from "vuex";

const FIELDS_DISPLAY = ["id", "name", "domain", "settings"];
const FIELDS_EDIT = ["settings"];

export default {
  components: { AdminModalForm },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
    };
  },
  computed: {
    ...mapState("core$common", ["node"]),
  },
  methods: {
    ...mapActions("core$common", ["getNodeByDomain"]),
    onEdit(data) {
      api
        .request({
          name: "editNode",
          pathParam: data.id,
          payload: data,
          showAlert: true,
        })
        .then(() => {
          this.getNodeByDomain();
        });
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
  },
};
</script>

<style lang="scss"></style>
